import { useState, useEffect } from 'react'
import Login from '../components/Login'
import { useCustomContext } from '../state/context';

import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Form, Button} from 'react-bootstrap'
import dayjs, { Dayjs } from 'dayjs';
export default function Home() {

  const { tournaments, shareAPI, deleteTournament, user, addTournament, clearAll } = useCustomContext();
  
  const [name, setName] = useState('');
  const [matchLength, setMatchLength] = useState(1)
  const [dateTime, setDateTime] = useState(dayjs('2022-04-17T15:30'))
  const [tournamentType, setTournamentType] = useState(0);
  const [location, setLocation] = useState('');
  const [notes, setNotes] = useState('');
  const [showAddTournament, setShowAddTournament] = useState(false)
  const [editTournament, setEditTournament] = useState([])
  const [editID, setEditID] = useState('')
  const addNewTournament = () => {
    addTournament(name, dateTime, tournamentType, location, matchLength, notes)
  }
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    setEditTournament(null)
    setShowAddTournament(false);
    setName('');
    setDateTime(dayjs('2022-04-17T15:30'));
    setLocation('');
    setNotes('')
    setEditID('')
    setMatchLength(1)
  }, [clearAll])



  return (
    <main >
      <h3>Tournaments (<span onClick={() => setShowAddTournament(old => !old)}>add</span>)</h3>
      {
        showAddTournament &&
        <>
          <h3>Add new tournament</h3>
          <Form.Label htmlFor="name">Name:</Form.Label>
          <Form.Control id="name" value={name} onChange={(e) => setName(e.target.value)} type="text" />

          <Form.Label htmlFor="Controlled picker">Date/Time:</Form.Label>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div 
            style={{marginBottom: '1rem'}}>
            <DateTimePicker
              value={dateTime}
              onChange={(newValue) => setDateTime(newValue)}
            />
            </div>
          </LocalizationProvider>
          <Form.Label htmlFor="location">Location:</Form.Label>
          <Form.Control id="name" value={location} onChange={(e) => setLocation(e.target.value)} type="text" />
          <Form.Label htmlFor="length">Match length:</Form.Label>
          <Form.Control id="length" value={matchLength} onChange={(e) => setMatchLength(e.target.value)} type="text" />
          <Form.Label htmlFor="notes">Notes:</Form.Label>
          <input multiline sx={{ marginBottom: 2 }} id="notes" value={notes} onChange={(e) => setNotes(e.target.value)} type="text" />
          <button onClick={addNewTournament}>Add tournament</button>
        </>
      }
      {
        user ?
          <>
            {
              <div className="players">
                <div className="players">
                  {
                    Array.isArray(tournaments) && tournaments.map(item => (
                      <div className="player" key={item.id}>
                        <div>
                        <p className="item-text"> <a href={"/tournament/" + item.slug}>{item.name}</a></p>
                          <p>Share: <a href={shareAPI + 't/' + item.slug}>{shareAPI + 't/' + item.slug}</a></p>
          
                        </div>
                        <div>
                          <p className="item-text">{dayjs(item.dateTime).format('DD/MM/YYYY @ HH:mm')}</p>
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
            }
          </>
          :
          <Login />
      }
    </main>
  )
}