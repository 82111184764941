
import { useState, useEffect } from 'react'
import score from '../img/score.png';
import { useParams } from 'react-router-dom'
import axios from 'axios';
import moment from 'moment';
import { Button, Form } from 'react-bootstrap'
import { useCustomContext } from '../state/context'
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import dayjs, { Dayjs } from 'dayjs';
function generateRandomString(length) {
  const characters = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let randomString = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomString += characters.charAt(randomIndex);
  }

  return randomString;
}
export default function Home() {
  const { slug } = useParams();
  const { user, api, updateTournament, deleteTournament, updateTournamentPlayers, updateMatches } = useCustomContext();
  const [players, setPlayers] = useState(null)
  const [tournament, setTournament] = useState(null)
  const [matches, setMatches] = useState([]);
  const [matchLength, setMatchLength] = useState(1)
  const [quickstart, setQuickstart] = useState('')
  const [playersArray, setPlayersArray] = useState([]);

  const startTournament = () => {
    const array = quickstart.split("\n");
    let newArray = [];
    array.forEach((item) => {
      if (item.trim() !== "") {
        let newItem = {
          name: item.trim(),
          wins: 0,
          losses: 0,
          points: 0,
          id: generateRandomString(10)
        };
        newArray.push(newItem);
      }
    });
    if (newArray.length < 2) {
      alert("You need to enter at least 2 names");
      return;
    }

    setPlayers(newArray);
    setPlayersArray(newArray);

    let newMatches = [];

    // Use forEach instead of nested for loops for better readability
    newArray.forEach((player1, i) => {
      newArray.forEach((player2, j) => {
        if (i !== j) {
          newMatches.push({
            player1,
            player2,
            winner: "",
          });
        }
      });
    });

    setMatches(newMatches);
    setQuickstart('');
  };
  
  const playGame = (winner, loser, index) => {
    setMatches((prevMatches) => {
      const updatedMatches = [...prevMatches];
      updatedMatches[index].winner = winner.id;
  
      const updatedPlayersArray = playersArray.map((player) =>
        player.id === winner.id ? { ...player, points: player.points + 1 } : player
      );
  
      console.log("Updated Matches:", updatedMatches);
      console.log("Updated Players Array:", updatedPlayersArray);
  
      // Set the updated players array back to state
      setPlayersArray(updatedPlayersArray);
  
      return updatedMatches;
    });
  };

  const sortedPlayers = playersArray.slice().sort((a, b) => b.points - a.points);


  return (
    <div className="demo" style={{paddingRight: '2rem'}}>
      <h2>Round Robin Demo</h2>
      {players ? (
        
        <div className="matches">
            <h3>Player Standings</h3>
            <ul>
              {sortedPlayers.map((player) => (
                <li key={player.id}>
                  {`${player.name}: ${player.points}`}
                </li>
              ))}
            </ul>
            <h3>Matches</h3>
          <ul>
            {matches.map((item, index) => (
              <li key={index}>
                {item.winner ? (
                  <>
                    {item.winner === item.player1.id ? (
                      `${item.player1.name} (${playersArray.find(player => player.id === item.player1.id)?.points || 0}) wins against ${item.player2.name} (${playersArray.find(player => player.id === item.player2.id)?.points || 0})`
                    ) : (
                      `${item.player2.name} (${playersArray.find(player => player.id === item.player2.id)?.points || 0}) wins against ${item.player1.name} (${playersArray.find(player => player.id === item.player1.id)?.points || 0})`
                    )}
                  </>
                ) : (
                  <>
                    <Button onClick={() => playGame(item.player1, item.player2, index)}>
                      {item.player1.name} ({playersArray.find(player => player.id === item.player1.id)?.points || 0}) wins
                    </Button>
                    <Button variant="success" onClick={() => playGame(item.player2, item.player1, index)}>
                      {item.player2.name} ({playersArray.find(player => player.id === item.player2.id)?.points || 0}) wins
                    </Button>
                  </>
                )}
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <>
          <label>Enter at least two players one line at a time:</label>
          <textarea className="form-control mb-2" value={quickstart} onChange={(e) => setQuickstart(e.target.value)} />
          <Button onClick={startTournament}>Start tournament</Button>
        </>
      )}
    </div>
  )
}