
import { useState, useEffect } from 'react'
import score from '../img/score.png';
import { useParams } from 'react-router-dom'
import axios from 'axios';
import moment from 'moment';

let api = "https://backgammon-club.vercel.app/";
api = 'http://localhost:4322/';

export default function Home() {
  const { slug } = useParams();
  const [tournament, setTournament] = useState(null);
  
  useEffect(() => {
    axios.post(api + 'main/getTournamentBySlug', {
      slug: slug
    })
      .then(results => {
        setTournament(results.data)
        console.log(results.data)
      })
      .catch(err => {
        console.log(err)
      })
  }, [])
  return (
    <div className="tournament">
      {
        tournament &&
        <>
             <h2>{tournament.name}</h2>
              <p><span className="bold">Date: </span>{moment(tournament.date).format('D/MM/YYYY')}</p>
              <p><span className="bold">Time: </span>{tournament.time.length > 0 && tournament.time.hours + ":" + tournament.time.minutes}</p>
              <p><span className="bold">Location: </span>{tournament.location}</p>
              <p><span className="bold">Notes: </span>{tournament.notes}</p>
              {/* <p><span className="bold">Tournament type: </span>{tournament.type === 0 ? "Round Robin" : tournament.type === 1 ? "Swiss" : "Single Elimination"}</p> */}
            {/* <h3>Players:</h3>
            <ul>
            {
              tournament.players.map((item) => {
                return(
                  <li key={item.id}>{item.name} - Elo: {item.elo}, Wins: {item.wins}, Loses: {item.losses}</li>
                )
              })
            }
            </ul> */}
            </>

      }
    </div>
  )
}