import { useState, useEffect } from 'react'
import Login from '../components/Login';
import { useCustomContext } from '../state/context';
import { Table, Modal, Button, Form, Container } from 'react-bootstrap';
import { getElo } from 'react-elo/Elo';
import { MdCheckBoxOutlineBlank, MdOutlineCheckBox } from "react-icons/md";

import axios from 'axios'
export default function Players() {

  const { removeItem, clearAll, user, players, api, addPlayer, updatePlayer, setReload, reload } = useCustomContext();

  const [quickstart, setQuickstart] = useState('')
  const [showAddPlayer, setShowAddPlayer] = useState(false);
  const [showGame, setShowGame] = useState(false);

  const [name, setName] = useState('');
  const [elo, setElo] = useState('');
  const [wins, setWins] = useState('');
  const [notes, setNotes] = useState('');
  const [losses, setLosses] = useState('');
  const [open, setOpen] = useState(false);
  const [editingPlayer, setEditingPlayer] = useState('');
  const [selectedPlayers, setSelectedPlayers] = useState([])
  const [matchLength, setMatchLength] = useState(1)
  const [instructions, setInstructions] = useState(false);
  
  const handleOpen = (player) => {
    setEditingPlayer(player);
    setOpen(true);
    setName(player.name);
    setWins(player.wins);
    setLosses(player.losses);
    setNotes(player.notes)
    setElo(player.elo)
  }
  useEffect(() => {
    setQuickstart('')
  }, [clearAll])

  useEffect(() => {
    if (selectedPlayers.length > 1) {
      setShowGame(true)
    }
  }, [selectedPlayers])

  const deletePlayer = () => {
    removeItem(editingPlayer.id, 'players');
    handleClose()
  }
  const handleClose = () => setOpen(false);

  const playGame = (winner, loser) => {
    const elos = getElo(parseInt(winner.elo), parseInt(loser.elo))
    winner.wins = parseInt(winner.wins) + 1;
    loser.losses = parseInt(loser.losses) + 1;
    winner.elo = elos.winNewElo;
    loser.elo = elos.loseNewElo;
    if (user) {
       axios.post(api + 'main/updateItem', {
         path: 'players/' + winner.id,
         item: winner
       });
       axios.post(api + 'main/updateItem', {
        path: 'players/' + loser.id,
        item: loser
      });
       setReload(old => !old)
    setSelectedPlayers([]);
    setShowGame(false);
  }
  }
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#222',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const savePlayer = () => {
    let item = {
      name: name,
      wins: wins,
      losses: losses,
      elo: elo,
      updatedAt: Date.now()
    }

    updatePlayer(editingPlayer.id, item)

    handleClose();
  }


  const addQuickstart = () => {
    const array = quickstart.split("\n");
    array.forEach(item => {
      if (item) {
        addPlayer(item, 1500)
      }
    });
    setShowAddPlayer(false)
  }

  const choosePlayers = (player) => {
    const isPlayerSelected = selectedPlayers.some((selectedPlayer) => selectedPlayer.id === player.id);

    if (!isPlayerSelected) {
      let newPlayers = [...selectedPlayers, player];

      // Ensure there are at most two items in the array
      if (newPlayers.length > 2) {
        newPlayers.shift(); // Remove the oldest item
      }

      setSelectedPlayers(newPlayers);
    } else {
      let newPlayers = selectedPlayers.filter((selectedPlayer) => selectedPlayer.id !== player.id);
     
      setSelectedPlayers(newPlayers);
    }
  };

  return (
    <div>
      {
        user ?
          <>
            <h2>Players</h2>
            
            {
              players.length > 0 &&
              <Table striped>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Wins</th>
                      <th>Losses</th>
                      <th>Avg</th>
                      <th>Elo</th>
                      <th>Play</th>
                     
                    </tr>
                  </thead>

                  <tbody>
                    {
                      players.map(item => {
                        return (
                          <tr key={item.id}>

                            <td onClick={() => handleOpen(item)}>{item.name} </td>
                            <td>{item.wins} </td>
                            <td>{item.losses} </td>
                            <td>
                              {isNaN(Math.ceil((item.wins / (item.wins + item.losses)) * 100)) ? 0 : Math.ceil((parseFloat(item.wins) / (parseFloat(item.wins) + parseFloat(item.losses))) * 100)}%
                            </td>
                            <td>{item.elo} </td>
                            <td>
                              {
                                (selectedPlayers[0] && selectedPlayers[0].id === item.id) || (selectedPlayers[1] && selectedPlayers[1].id === item.id) ? (
                                  <MdOutlineCheckBox size={24} onClick={() => choosePlayers(item)} />
                                ) : (
                                  <MdCheckBoxOutlineBlank size={24} onClick={() => choosePlayers(item)} />
                                )
                              }


                            </td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </Table>
            }
            <>
            <br />
                <p>Add new players one line at a time.</p>
                <textarea className="form-control mb-4" style={{height: 80}} value={quickstart} onChange={(e) => setQuickstart(e.target.value)} />
                
                <Button onClick={addQuickstart}>Add players</Button>
              </>
          </>
          :
          <Login />
      }
      <Modal
        show={open}
        onClose={handleClose}
        onHide={handleClose}
      > 
      <Modal.Dialog
      style={{width: '100%'}}
      className="p-2">
        <Modal.Header closeButton>
          <Modal.Title>Edit Player</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Form.Label htmlFor="name">Name:</Form.Label>
            <Form.Control id="name" value={name} onChange={(e) => setName(e.target.value)} type="text" />
            <Form.Label htmlFor="location">Wins:</Form.Label>
            <Form.Control id="location" value={wins} onChange={(e) => setWins(e.target.value)} type="text" />
            <Form.Label htmlFor="email">Losses:</Form.Label>
            <Form.Control id="email" value={losses} onChange={(e) => setLosses(e.target.value)} type="text" />
            <Form.Label htmlFor="phone">Elo:</Form.Label>
            <Form.Control id="phone" value={elo} onChange={(e) => setElo(e.target.value)} type="text" />
            <Form.Label htmlFor="notes">Notes:</Form.Label>
            <textarea multiline sx={{ marginBottom: 2 }}  style={{height: 50}} id="notes" value={notes} onChange={(e) => setNotes(e.target.value)} />
          </Form>
          <Button onClick={savePlayer}>Save</Button>
          <Button className="ml-4" variant="danger" onClick={deletePlayer}>Delete</Button>
          </Modal.Body>
          </Modal.Dialog>
      </Modal>
      <Modal
       onHide={() => setShowGame(false)}
        show={showGame}
        onClose={() => setShowGame(false)}
      >
      <Modal.Dialog
      style={{width: '100%'}}
      className="p-2">
        <Modal.Header closeButton>
          <Modal.Title>Who won?</Modal.Title>
        </Modal.Header>

        <Modal.Body>
        <Button onClick={() => playGame(selectedPlayers[0], selectedPlayers[1])}>{selectedPlayers[0] && selectedPlayers[0].name} won</Button>
        <br /><br />
        <Button className="mb-2" variant="success" onClick={() => playGame(selectedPlayers[1], selectedPlayers[0])}>{selectedPlayers[1] && selectedPlayers[1].name} won</Button>
            <Form.Label htmlFor="match">Match Length:</Form.Label>
            <Form.Control className="mb-2" id="match" value={matchLength} onChange={(e) => setMatchLength(e.target.value)} type="text" />
            
          <Button variant='warning' onClick={() => setShowGame(false)}>Cancel</Button>
        </Modal.Body>
        </Modal.Dialog>
      </Modal>
    </div>
  )
}