import Table from '@mui/material/Table';
export default function PlayersImport(props) {

  return (
    <div>
      <h2>Players</h2>

      {
        props.players.length > 0 &&
          <Table striped>
            <thead>
              <tr>
                <th>Name</th>
                <th>Wins</th>
                <th>Losses</th>
                <th>Avg</th>
                <th>Elo</th>
              </tr>
            </thead>

            <tbody>
              {
                props.players.map(item => {
                  return (
                    <tr key={item.id}>

                      <td>{item.name} </td>
                      <td>{item.wins} </td>
                      <td>{item.losses} </td>
                      <td>
                        {isNaN(Math.ceil((item.wins / (item.wins + item.losses)) * 100)) ? 0 : Math.ceil((parseFloat(item.wins) / (parseFloat(item.wins) + parseFloat(item.losses))) * 100)}%
                      </td>
                      <td>{item.elo} </td>

                    </tr>
                  )
                })
              }
            </tbody>
          </Table>
      }
    </div>
  )
}