
import { useState, useEffect } from 'react'
import score from '../img/score.png';
import { useParams } from 'react-router-dom'
import axios from 'axios';
import moment from 'moment';
import { useCustomContext } from '../state/context'
import PlayersImport from '../components/PlayersImport';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import dayjs, { Dayjs } from 'dayjs';

export default function Home() {
  const { slug } = useParams();
  const { user, api, updateTournament, deleteTournament, updateTournamentPlayers, players, updateMatches } = useCustomContext();

  const [tournament, setTournament] = useState(null)
  useEffect(() => {
    if (user) {
      axios.post(api + 'main/getItemByChild', {
        id: slug,
        path: 'tournaments/',
        qualifier: 'slug'
      })
        .then(results => {
          setTournament(results.data[0])
        })
        .catch(err => {
          console.log(err)
        })
    }
  }, [user])


  return (
    <div className="tournament">
      {
        tournament &&
        <>
        <h2>{tournament.name}</h2>
        <h3>{dayjs(tournament.dateTime).format('DD/MM/YYYY @ HH:mm')}</h3>
        <h3>{tournament.location}</h3>
        <PlayersImport players={players} />
        <h4>Notes</h4>

        <p style={{whiteSpace: "pre-wrap"}}>{tournament.notes}</p>
        </>
      }
    </div>
  )
}