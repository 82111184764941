const apps = {
  club: {
    title: "Club",
    header: "Profile",
    subtitle: "Elo in your pocket",
    slug: "/profile",
    backgroundColor: '#222',
    textColor: '#eee',
  },
  tournaments: {
    title: "Tournaments",
    header: "Tournaments",
    subtitle: "Elo in your pocket",
    slug: "/tournaments",
    backgroundColor: '#222',
    textColor: '#eee',
  },
  docs: {
    title: "Docs",
    header: "Docs",
    subtitle: "Elo in your pocket",
    slug: "/docs",
    backgroundColor: '#222',
    textColor: '#eee',
  },
  contact: {
    title: "Contact",
    header: "Contact",
    subtitle: "Elo in your pocket",
    slug: "/contact",
    backgroundColor: '#222',
    textColor: '#eee',
  },
}

export default apps;