import React, { useState, useEffect } from 'react';
import Demo from './Demo';
import {
  getAuth, UserCredential,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged, GoogleAuthProvider, signInWithPopup, signInWithRedirect,
  signOut
} from "firebase/auth";
import { Form, Button } from 'react-bootstrap'

import { useCustomContext } from '../state/context';

export default function App() {
  const { setUser, addClub } = useCustomContext();
  const auth = getAuth();
  const provider = new GoogleAuthProvider();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");

  function handleCreate() {
    if (email && password) {
      createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // Signed in
          setUser(userCredential.user);
          addClub(userCredential.user)

        })
        .catch((error) => {
          setMessage(error.message);
        });
    } else {
      setMessage("Please fill out both fields.");
    }
  }

  function handleLogin(e) {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        setUser(userCredential.user);
      })
      .catch((error) => {
        setMessage(error.message);
      });
  }
  useEffect(() => {
    onAuthStateChanged(auth, (userCredential) => {
      // check if user is already logged in
      if (userCredential) {
        setUser(userCredential);

      } else {
        setUser(null);
      }
    });
  }, []);
  function signInGoogle() {
    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        // IdP data available using getAdditionalUserInfo(result)
        // ...
      }).catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });
  }
  return (
    <main className="login">
         <Demo />
      <div className="login-panel">
        <h2>Login</h2>
        {message && <p className="alert">{message}</p>}
        <Form.Label htmlFor="email">Email:</Form.Label>
        <Form.Control id="email" value={email} onChange={(e) => setEmail(e.target.value)} type="text" />
        <Form.Label htmlFor="password">Password:</Form.Label>
        <Form.Control id="password" value={password} onChange={(e) => setPassword(e.target.value)} type="password" />
      <br />
        <Button type="submit" onClick={(e) => handleLogin(e)}>
          Login
        </Button>
        <br /><br />
        <Button variant="success" type="submit" onClick={(e) => handleCreate(e)}>
          Create Account
        </Button>
      </div>
    </main>
  )
}