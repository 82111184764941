import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { getElo } from 'react-elo/Elo';

import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
const Context = createContext();


function generateRandomString(length) {
  const characters = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let randomString = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomString += characters.charAt(randomIndex);
  }

  return randomString;
}

let apidata = "http://localhost:4322/";
 apidata = "https://backgammon-club.vercel.app/"
let shareAPI = "http://localhost:3000/";
shareAPI = "https://club.backgammonclub.club/"
export const ContextProvider = ({ children }) => {
  const auth = getAuth();
  const [user, setUser] = useState(null);
  const [api, setAPI] = useState(apidata);
  const [userInfo, setUserInfo] = useState(null);
  const [selectedPlayer, setSelectedPlayer] = useState([]);
  const [reload, setReload] = useState(false);
  const [tournament, setTournament] = useState(null);
  const [players, setPlayers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [clearAll, setClearAll] = useState(false);
  const [tournaments, setTournaments] = useState([]);
  const [club, setClub] = useState([]);

  useEffect(() => {

    onAuthStateChanged(auth, (userCredential) => {
      // check if user is already logged in
      if (userCredential) {
        setUser(userCredential);

      } else {
        setUser(null);
      }
    });
  }, []);



  useEffect(() => {
    if (user) {
      axios.post(api + "main/getItemByChild", {
        id: user.uid,
        path: 'players',
        qualifier: 'clubid'
      })
        .then(results => {
          setPlayers(results.data);
          // Sort the array by the "elo" property
          setPlayers((players) => [...players].sort((b, a) => a.elo - b.elo));
          setLoading(false)
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [user, reload]);

  useEffect(() => {
    if (user) {
      axios.post(api + "main/getItemByChild", {
        id: user.uid,
        path: 'tournaments',
        qualifier: 'uid'
      })
        .then(results => {
          if (results.data) {
            const sortedArray = results.data.sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime));
            setTournaments(sortedArray);
          }
          else {
            setTournaments([])
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [user, reload]);


  const logout = () => {
    signOut(auth);
    setClearAll(old => !old)
    setUser(null);
  }

  const deletePlayer = async (id) => {
    const updatedPlayers = players.filter(player => player.id !== id);
    setPlayers(updatedPlayers);

    if (user) {
      axios.post(api + "main/deleteItem", {
        path: 'players/' + id
      })
        .then(results => {
        })
        .catch(err => {
          console.log(err);
        });
      setReload(old => !old);
    }
  };

  const addPlayer = (newPlayer, newElo) => {
    let newPlayers = players;
    let newPlayerToAdd = {
      id: generateRandomString(10),
      clubid: user.uid,
      name: newPlayer,
      elo: newElo,
      wins: 0,
      losses: 0,
      createdAt: Date.now(),
      updatedAt: Date.now()
    };
    newPlayers.push(newPlayerToAdd);
    setPlayers(newPlayers);
    if (user) {
      axios.post(api + "main/addItem", {
        path: 'players/',
        item: newPlayerToAdd
      })
        .then(() => {
        })
        .catch(err => {
          console.log(err);
        });
    }
    setClearAll(old => !old);
  };


  const addClub = (user) => {
    let item = {
      uid: user.uid,
      name: '',
      contactName: '',
      location: '',
      email: '',
      phone: '',
      notes: '',
      slug: generateRandomString(10),
      createdAt: Date.now(),
      updatedAt: Date.now()
    };
      axios.post(api + "main/addItem", {
        path: 'clubs/',
        item: item
      })
        .then(() => {
          console.log("Club added")
        })
        .catch(err => {
          console.log(err);
        });
    
  };

  const updatePlayer = async (id, newPlayer) => {
    if (user) {
      axios.post(api + 'main/updateItem', {
        path: 'players/' + id,
        item: newPlayer
      });
      setReload(old => !old)
    }
  };
  const updateMatches = (winner, loser, tournamentid, index) => {
    const elos = getElo(parseInt(winner.elo), parseInt(loser.elo))
    winner.wins = winner.wins + 1;
    loser.losses = loser.losses + 1;
    winner.elo = elos.winNewElo;
    loser.elo = elos.loseNewElo;
    winner.points = winner.points + 1
    if (user) {
      axios.post(api + 'main/updateItem', {
        path: 'players/' + winner.id,
        item: winner
      });
      axios.post(api + 'main/updateItem', {
        path: 'players/' + loser.id,
        item: loser
      });
      setReload(old => !old)
      axios.post(api + 'main/updateItem', {
        path: 'tournaments/' + tournamentid + '/players/' + index,
        item: winner
      });
      axios.post(api + 'main/updateItem', {
        path: 'tournaments/' + tournamentid + '/players/' + index,
        item: loser
      });
      setReload(!reload)
    }
  };

  const updateTournament = async (id, tournament) => {
    if (user) {
      axios.post(api + 'main/updateItem', {
        path: 'tournaments/' + id,
        item: tournament
      });
      setReload(old => !old)
      setClearAll(old => !old)
    }
  };

  const updateTournamentPlayers = async (id, newPlayers) => {
    if (user) {
      axios.post(api + 'main/updateItem', {
        path: 'tournaments/' + id + '/players',
        item: newPlayers
      });
      setReload(old => !old)
      setClearAll(old => !old)
    }
  };

  const updateClub = (clubInfo) => {
    console.log(clubInfo)
      axios.post(api + 'main/updateItem', {
        path: 'clubs/' + clubInfo.id,
        item: clubInfo
      });
  };


  const addTournament = async (name, dateTime, tournamentType, location, matchLength, notes) => {
    if (!name) {
      alert("Please fill in a name");
      return;
    }
    const item = {
      dateTime: dateTime,
      type: tournamentType,
      name: name,
      location: location,
      matchLength: matchLength,
      notes: notes,
      slug: generateRandomString(10),
      createdAt: Date.now(),
      updatedAt: Date.now()
    };
    item.uid = user.uid
    if (user) {
      axios.post(api + "main/addItem", {
        path: 'tournaments/',
        item: item
      })
        .then(() => {
        })
        .catch(err => {
          console.log(err);
        });
    }

    setClearAll(old => !old);
    setReload(old => !old);
    let newTournaments = Array.isArray(tournaments) ? [...tournaments] : [];

    newTournaments.push(item);
    setTournaments(newTournaments);
  };

  const deleteTournament = async (id) => {

    if (user) {
      axios.post(api + "main/deleteItem", {
        path: 'tournaments/' + id
      })
        .then(results => {

        })
        .catch(err => {
          console.log(err);
        });
      setReload(old => !old);
      setClearAll(old => !old)
    }
  };

  const removeItem = async (id, path) => {

    if (user) {
      axios.post(api + "main/deleteItem", {
        path: path + '/' + id
      })
        .then(results => {

        })
        .catch(err => {
          console.log(err);
        });
      setReload(old => !old);
      setClearAll(old => !old)
    }
  };


  return (
    <Context.Provider value={{
      deletePlayer,
      addClub,
      club,
      addPlayer,
      updatePlayer,
      addTournament,
      deleteTournament,
      updateTournament,
      tournaments,
      removeItem,
      updateTournamentPlayers,
      updateMatches,
      clearAll,
      setReload,
      setUser,
      setPlayers,
      setLoading,
      setClearAll,
      setTournament,
      tournament,
      setSelectedPlayer,
      api,
      shareAPI,
      reload,
      userInfo,
      players,
      loading,
      user,
      setUser,
      logout,
      updateClub
    }}>
      {children}
    </Context.Provider>
  );
};

export const useCustomContext = () => useContext(Context);
