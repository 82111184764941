import { useState, useEffect } from 'react'

import Login from '../components/Login'
import { useCustomContext } from '../state/context';
import axios from 'axios'
import { Button, Form } from 'react-bootstrap';

export default function Home() {

  const { user, logout, api, reload, shareAPI } = useCustomContext();

  const [name, setName] = useState('');
  const [contactName, setContactName] = useState('')
  const [notes, setNotes] = useState('');
  const [email, setEmail] = useState('');
  const [location, setLocation] = useState('');
  const [phone, setPhone] = useState('')
  const [slug, setSlug] = useState('');
  const [loading, setLoading] = useState(false);
  const [club, setClub] = useState([])
  const [clubID, setClubID] = useState('')

  useEffect(() => {
    if (user) {
      axios.post(api + "main/getItemByChild", {
        id: user.uid,
        path: 'clubs',
        qualifier: 'uid'
      })
        .then(results => {
          const data = results.data[0]
          setClub(data);
          console.log(data)
          setName(data.name);
          setNotes(data.notes);
          setEmail(data.email);
          setContactName(data.contactName);
          setPhone(data.phone);
          setLocation(data.location);
          setSlug(data.slug);
          setClubID(data.id)
          setLoading(false)
        })
        .catch(err => {
          console.log(err);
          setLoading(false)
        });
    }
    else
    {
      console.log("No user")
    }
  }, [user]);


  const saveClub = () => {
    const clubInfo = {
      name: name,
      notes: notes,
      location: location,
      email: email,
      phone: phone,
      uid: user.uid,
      id: clubID,
      contactName: contactName,
      updatedAt: Date.now()
    }
    axios.post(api + 'main/updateItem', {
      path: 'clubs/' + clubInfo.id,
      item: clubInfo
    });
  };

  if (loading) {
    return (<p>Loading</p>)
  }

  return (
    <main>
      {
        user ?
          <>
            <Button onClick={logout}>Logout</Button>
            <p>Leave fields blank and they won't show up on your Club's page.</p>
            <p>Share link: <a href={shareAPI + "c/" + slug}>{shareAPI + "c/" + slug}</a></p>

            <Form>
              <Form.Label htmlFor="name">Club Name:</Form.Label>
              <Form.Control id="name" value={name} onChange={(e) => setName(e.target.value)} type="text" />
              <Form.Label htmlFor="contact">Contact Name:</Form.Label>
              <Form.Control id="contact" value={contactName} onChange={(e) => setContactName(e.target.value)} type="text" />
             
              <Form.Label htmlFor="location">Location:</Form.Label>
              <Form.Control id="location" value={location} onChange={(e) => setLocation(e.target.value)} type="text" />
              <Form.Label htmlFor="email">Email:</Form.Label>
              <Form.Control id="email" value={email} onChange={(e) => setEmail(e.target.value)} type="text" />
              <Form.Label htmlFor="phone">Phone:</Form.Label>
              <Form.Control id="phone" value={phone} onChange={(e) => setPhone(e.target.value)} type="text" />
              <Form.Label htmlFor="notes">Notes:</Form.Label>
              <textarea className="form-control" style={{ height: 50 }} id="notes" value={notes} onChange={(e) => setNotes(e.target.value)} />
            </Form>
            <Button onClick={saveClub}>Save</Button>
          </>
          :
          <Login />
      }
    </main>
  )
}