
import Login from '../components/Login'
import { useCustomContext } from '../state/context';
import Players from './Players';
import { signOut, getAuth } from 'firebase/auth';
import Button from '@mui/material/Button';

export default function Home() {

  const { user, loading } = useCustomContext();
  const auth = getAuth();
  return (
    <main >
      {
        user ?
          <Players />
          :

          <Login />
      }
      </main>
  )
}