import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useCustomContext } from '../state/context';
import axios from 'axios'
import PlayersImport from '../components/PlayersImport';

export default function Club() {
  const { slug } = useParams();
  const { api, players } = useCustomContext()
  const [club, setClub] = useState('')
  
  useEffect(() => {
    axios.post(api + 'main/getItemByChild', {
      id: slug,
      qualifier: 'slug',
      path: 'clubs'
    })
    .then(results => {
      setClub(results.data[0])
      console.log(results.data[0])
    })
    .catch(err => {
      console.log(err)
    })
  }, [])

  return (
    <main>
      <h2>{club.name}</h2>
      <h3>Contact information</h3>
      <p>Name: {club.contactName}
      <br />Phone: {club.phone}
      <br />Email: <a href={"mailto: " + club.email}>{club.email}</a></p>
      <h3>Location</h3>
      <p style={{whiteSpace: "pre"}}>{club.location}</p>
      <h3>Notes</h3>
      <p>{club.notes}</p>
      <PlayersImport players={players} />
    </main>
  )
}