
import { useState, useEffect } from 'react'
import score from '../img/score.png';
import { useParams } from 'react-router-dom'
import axios from 'axios';
import moment from 'moment';
import { useCustomContext } from '../state/context'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { Table, Modal, Button, Form, Container } from 'react-bootstrap';

import { MdCheckBoxOutlineBlank, MdOutlineCheckBox } from "react-icons/md";

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import FormControlLabel from '@mui/material/FormControlLabel'
import { Checkbox } from '@mui/material';

import Box from '@mui/material/Box';
import dayjs, { Dayjs } from 'dayjs';
import { LabelImportantSharp } from '@mui/icons-material';

export default function Home() {
  const { slug } = useParams();
  const { user, api, shareAPI, updateTournament, deleteTournament, updateTournamentPlayers, players, updateMatches } = useCustomContext();
  const [activeTab, setActiveTab] = useState(0);
  
  const [name, setName] = useState('');
  const [dateTime, setDateTime] = useState(dayjs('2022-04-17T15:30'))
  const [tournamentType, setTournamentType] = useState(0);
  const [location, setLocation] = useState('');
  const [notes, setNotes] = useState('');
  const [editID, setEditID] = useState('')
  const [tournament, setTournament] = useState(null)
  const [matches, setMatches] = useState([]);
  const [matchLength, setMatchLength] = useState(1)

  const [playersArray, setPlayersArray] = useState([]);


  const handleCheckboxChange = (player) => {
    // If the player is already in the array, remove it; otherwise, add it with points: 0
    const updatedPlayersArray = playersArray.some((p) => p.id === player.id)
      ? playersArray
        .filter((p) => p.id !== player.id)
        .map((p) => ({ ...p, points: 0 }))
      : [...playersArray, { ...player, points: 0 }];

    // Update the state
    setPlayersArray(updatedPlayersArray);
    console.log(updatedPlayersArray);
    // Call your function with the updated state
    updateTournamentPlayers(slug, updatedPlayersArray);
  };

  useEffect(() => {
    // This useEffect will run whenever playersArray changes
    updateTournamentPlayers(editID, playersArray);
  }, [editID, playersArray]);

  const generateTournament = () => {
    let newMatches = [];

    // Use forEach instead of nested for loops for better readability
    playersArray.forEach((player1, i) => {
      playersArray.forEach((player2, j) => {
        if (i !== j) {
          newMatches.push({
            player1,
            player2,
            winner: "",
          });
        }
      });
    });

    // Assuming `matches` is a state variable
    setMatches(newMatches);
    let newTournament = tournament;
    newTournament.matches = newMatches;

    // Assuming `updateTournament` and `tournament` are defined
    updateTournament(tournament.id, newTournament);
  };

  useEffect(() => {
    if (user) {
      axios.post(api + 'main/getItemByChild', {
        id: slug,
        path: 'tournaments/',
        qualifier: 'slug'
      })
        .then(results => {
          setTournament(results.data)
          const data = results.data[0];
          // if (data.id && data.id !== user.uid) {
          //   alert("You have accessed a private page. Please try again.")
          //   return
          // }
          if (data.players) {
            setPlayersArray(data.players)
          }
          setEditID(data.id)
          setName(data.name);
          if (data.matches) {
            setMatches(data.matches)
          }
          setLocation(data.location);
          setMatchLength(data.matchLength);
          setDateTime(dayjs(data.dataTime));
          setNotes(data.notes);
        })
        .catch(err => {
          console.log(err)
        })
    }
  }, [user])



  const editTheTournament = () => {
    let item = {
      name: name,
      location: location,
      dateTime: dateTime,
      notes: notes,
      matchLength: matchLength,
      players: playersArray,
      updatedAt: Date.now()
    }

    updateTournament(tournament.id, item)

  }

  const playGame = (winner, loser, index) => {
    // Update the matches array to mark the winner
    const updatedMatches = [...matches];
    updatedMatches[index].winner = winner.id;
    setMatches(updatedMatches);
    updateMatches(winner, loser, tournament.id, index)
    // Call your updateMatches function here if needed
    // updateMatches(winner, loser, tournament.id, index);
  };

  return (
    <div className="tournament">
      {
        tournament &&
        <>
          {/* <div className="tournament-tabs">
            <ul>
              <li onClick={() => setActiveTab(0)}>Info</li>
              <li onClick={() => setActiveTab(1)}>Tournament</li>
            </ul>
          </div> */}
          {
            activeTab === 0 ?
              <div style={{ display: 'flex' }}>
                <div className="sidebar">
                  <Form.Label htmlFor="name">Name:</Form.Label>
                  <Form.Control id="name" value={name} onChange={e => setName(e.target.value)} type="text" />
                  <p>Share link: <a href={shareAPI + "t/" + slug}>{shareAPI + "t/" + slug}</a></p>
                  <Form.Label htmlFor="Controlled picker">Date/Time:</Form.Label>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>

                    <DateTimePicker
                      value={dateTime}
                      onChange={(newValue) => setDateTime(newValue)}
                    />
                  </LocalizationProvider>
                  <Form.Label htmlFor="location">Location:</Form.Label>
                  <Form.Control id="location" value={location} onChange={(e) => setLocation(e.target.value)} type="text" />
                  <Form.Label htmlFor="length">Match length:</Form.Label>
                  <Form.Control id="length" value={matchLength} onChange={(e) => setMatchLength(e.target.value)} type="text" />

                  <Form.Label htmlFor="notes">Notes:</Form.Label>
                  <textarea sx={{ marginBottom: 2 }} id="notes" value={notes} onChange={(e) => setNotes(e.target.value)} type="text" />
                  <br />
                  <button onClick={editTheTournament}>Save</button>
                  <button onClick={() => deleteTournament(editID)}>Delete</button>
                </div>
                <div className="add-players">
                  <h2>Players</h2>
                  <div className="tournamentPlayers">
                    {players && players.map((item, index) => {
                      return (
                      <FormControlLabel
                        key={item.id}
                        control={
                          <Checkbox
                            checked={playersArray.some((p) => p.id === item.id)}
                            onChange={() => handleCheckboxChange(item)}
                          />
                        }
                        label={item.name}
                      />
                    )})}
                  </div>
                </div>
              </div>
              :
              <>
                {
                  matches.length < 1 ?
                    <button onClick={generateTournament}>Generate Tournament</button>
                    :
                    <div>
                      <button onClick={generateTournament}>Regenerate Tournament</button>
                      <h2>{tournament.name}</h2>
                      {
                        matches &&
                        <div className="matches">
                          <ul>
                            {matches.map((item, index) => (
                              <li key={index}>
                                {item.winner ? (
                                  <>
                                    {item.winner === item.player1.id ? (
                                      `${item.player1.name} (${item.player1.elo} / ${item.player1.points}) wins against ${item.player2.name} (${item.player2.elo} / ${item.player2.points}) )`
                                    ) : (
                                      `${item.player2.name} (${item.player2.elo} / ${item.player2.points}) ) wins against ${item.player1.name} (${item.player1.elo} / ${item.player1.points}) )`
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <button onClick={() => playGame(item.player1, item.player2, index)}>
                                      {item.player1.name} ({item.player1.elo}) wins
                                    </button>
                                    <button onClick={() => playGame(item.player2, item.player1, index)}>
                                      {item.player2.name} ({item.player2.elo}) wins
                                    </button>
                                  </>
                                )}
                              </li>
                            ))}
                          </ul>
                        </div>
                      }
                    </div>

                }
              </>
          }
        </>
      }
    </div>
  )
}