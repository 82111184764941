
export default function App() {

  return (
    <footer>
    <h2>Need support?</h2>
    <p>Email me any time at <a href="mailto:i@gruman.co">i@gruman.co</a>.</p>
    </footer>
  );
}
