import {useState} from 'react'
import axios from 'axios';
import { useCustomContext } from '../state/context';

export default function Home() {

  const { user, tournaments } = useCustomContext();
  const [email, setEmail] = useState('');
  const [body, setBody] = useState('')

  const sendEmail = () => {
    if (!email || !body) {
      alert("Please fill in both fields");
      return
    }
    axios.post('https://email-gruman.vercel.app', {
      email: 'matthewgruman@gmail.com',
      subject: 'Email from Backgammon Club website',
      body: "From " + email + "\n\n" + body
    })
    setEmail('')
    setBody('')
  }

  return (
    <main >
      <h1>Contact</h1>
      <label>Your email:</label>
      <input value={email} onChange={e => setEmail(e.target.value)} type="text" />
      <label>Your message:</label>
      <textarea value={body} onChange={e => setBody(e.target.value)}  />
      <button onClick={sendEmail}>Send email</button>
    </main>
  )
}