import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import apps from '../constants/apps'; // Ensure this import is at the top

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Container from 'react-bootstrap/Container';
import { useCustomContext } from '../state/context';
import { Link } from 'react-router-dom'
const appKeys = Object.keys(apps);

export default function App() {

  const navigate = useNavigate();
  const { user } = useCustomContext();

  return (
    <Navbar expand="lg" className="bg-body-primary">
        <Navbar.Brand href="/">Club Backgammon Club</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {
              user &&
              <>
              
            <Nav.Link href="/tournaments">Tournaments</Nav.Link>
            <Nav.Link href="/profile">Profile</Nav.Link>
            </>
            }
            <Nav.Link href="/docs">Docs</Nav.Link>
            {/* <Nav.Link href="/contact">Contact</Nav.Link> */}
            
          </Nav>
        </Navbar.Collapse>
    </Navbar>
  );
}
