
import appStoreImage from '../img/app-store.png';
import googlePlayImage from '../img/google-play-badge.png';
import clock from '../img/clock.png';
import club from '../img/club.png';
import score from '../img/score.png';
import { useParams } from 'react-router-dom';
import apps from '../constants/apps';
import Login from '../components/Login'
import { useCustomContext } from '../state/context';

export default function Home() {

  return (
    <main >
      <h2>Club Backgammon Club</h2>
      <p>Club Backgammon Club is a Backgammon Club organizer by <a href="https://matthewgruman.com">Matthew Gruman</a>.</p>
      <h2>Your Club</h2>
      <p>You must login to create your club.</p>
    </main>
  )
}