import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './constants/firebase'
import Home from './pages/Home';
import Profile from './pages/Profile';
import ClubTournament from './pages/ClubTournament';
import Tournaments from './pages/Tournaments';
import Tournament from './pages/Tournament';
import Players from './pages/Players';
import Player from './pages/Player';
import Contact from './pages/Contact';
import Docs from './pages/Docs';
import Club from './pages/Club'
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ContextProvider } from './state/context';
import { Container } from 'react-bootstrap'
function App() {
  return (
    <ContextProvider>
      <BrowserRouter>

      <Container>
          <Navbar />

          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/t/:slug" element={<ClubTournament />} />
            <Route path="/tournament/:slug" element={<Tournament />} />
            <Route path="/c/:slug" element={<Club />} />
            <Route path="/tournaments/" element={<Tournaments />} />
            <Route path="/player/:slug" element={<Player />} />
            <Route path="/players/" element={<Players />} />
            <Route path="/docs" element={<Docs />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
          <Footer />
          </Container>
      </BrowserRouter>
      </ContextProvider>
  );
}

export default App;
